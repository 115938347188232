import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { provideRouter } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DownloadAppComponent } from '../wallet/download-app/download-app.component';
import { DynamicIncentiveProgramComponent } from './incentive-program/dynamic-incentive-program/dynamic-incentive-program.component';
import { SubmitFeedbackFormComponent } from './incentive-program/dynamic-incentive-program/submit-feedback-form/submit-feedback-form.component';
import { IncentiveProgramComponent } from './incentive-program/incentive-program.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    TranslateModule,
    IncentiveProgramComponent,
    DownloadAppComponent,
    DynamicIncentiveProgramComponent,
    SubmitFeedbackFormComponent,
  ],
  providers: [
    provideRouter([
      {
        path: '',
        component: IncentiveProgramComponent,
      },
    ]),
  ],
})
export class IncentiveProgramModule {}
